import styled from "styled-components";

export const TeamContainer = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }
`;

export const TeamList = styled.ul`
  list-style: none;
  padding: 0 0 0 10px;

  & > li {
    margin: 15px 0;
  }
`;
