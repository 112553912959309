import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Layout } from '../../Layout';
import { Container } from '../../styles/pages/Expedicion.styles';

import * as S from '../../styles/pages/Equipo.styles';

const Equipo = () => {
  return (
    <Layout title="Equipo">
      <Container>
        <S.TeamContainer>
          <StaticImage
            src="../../images/equipo/team.jpg"
            alt="imagen"
            placeholder="blurred"
          />

          <S.TeamList>
            <li>
              <strong>Sebastián Escalona -</strong> Director
            </li>
            <li>
              <strong>Jaime Salamanca -</strong> Director Técnico
            </li>
            <li>
              <strong>Hemany Molina -</strong> Directora Agrupación Selk'nam
              Chile
            </li>
            <li>
              <strong>Francisco Echeverría -</strong> Productor Ejecutivo
            </li>
            <li>
              <strong>Macarena Simonetti -</strong> Productora
            </li>
            <li>
              <strong>Juan Lopez -</strong> Documental
            </li>
            <li>
              <strong>Antonio Altamirano -</strong> Productor en terreno
            </li>
            <li>
              <strong>Pedro López -</strong> Foto fija
            </li>
          </S.TeamList>
        </S.TeamContainer>
        <StaticImage
          src="../../images/equipo/dir.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <p>
          Sebastián Escalona es artista visual, pintor, diseñador escenográfico,
          investigador. Licenciado en Artes Visuales de la Universidad de Chile.
          Su obra como artista está orientada a la integración de las artes
          visuales hacia otros lenguajes. Ha sido seleccionado por Bob Wilson y
          Marina Abrahamovic en el laboratorio de vanguardias artísticas
          Watermill Center, New York para desarrollar su proyecto Rito Extinto.
        </p>

        <p>
          Es artista residente del Teatro Municipal de Santiago de Chile como
          Diseñador Integral, en la tetralogía operática de Richard Wagner “El
          anillo del Nibelungo”, ganadora mejor opera 2019, según el círculo de
          críticos de arte. Hoy en su tercera etapa, “Sigfrido”. Ha sido
          seleccionado por Olga Garay English, Directora del Teatro, John Aston
          Ford, Hollywood y Sarah Swinford para desarrollar sus proyectos en la
          residencia para artistas de excelencia Loghaven Knoxville 2020.
          Recientemente su carrera como artista y escenógrafo a sido destacada
          como una de las más relevantes de los últimos 6 años siendo invitado a
          representar a Chile, en el marco de la cuadrienal de diseño escénico
          de Praga, República Checa.
        </p>

        <p>
          Sus investigaciones artísticas que vinculan al pueblo Selk`nam y la
          Bauhaus, han sido seleccionadas en el marco de la celebración de los
          100 años de la Bauhaus en el mundo e invitado a exponer sus visiones a
          las escuelas fundacionales de Weimar, Dessau y Berlín, formando parte
          del libro Bauhaus 100 años publicado por Centro Cultural La Moneda.
          Invitado por Elka Rifkin ha sido jurado para seleccionar a artistas en
          el mundo para residir en Watermill Center, New York. Hoy prepara su
          exposición de pinturas de gran formato, Murasaki, dedicadas a su hija
          Violeta.
        </p>
        <hr />
      </Container>
    </Layout>
  );
};

export default Equipo;
